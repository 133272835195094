interface Time {
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
  days: number;
}
const zeroTime: Time = {
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
  milliseconds: 0,
};

export function getTime(startTime: Date): Time {
  const ms = getDifference(startTime);
  const sec = msToSeconds(ms);
  const min = secondsToMins(sec);
  const hr = minsToHours(min);
  const day = hoursToDays(hr);
  return day;
}

export function getDifference(startTime: Date): number {
  const curTime = Date.now();
  const startTimeMS = Date.parse(startTime.toString());
  return curTime - startTimeMS;
}

export function msToSeconds(ms: number): Time {
  const seconds: number = Math.floor(ms / 1000);
  const milliseconds: number = ms % 1000;
  return { ...zeroTime, seconds, milliseconds };
}
export function secondsToMins(time: Time): Time {
  const minutes = Math.floor(time.seconds / 60);
  const seconds = time.seconds % 60;
  return { ...time, seconds, minutes };
}
export function minsToHours(time: Time): Time {
  const hours = Math.floor(time.minutes / 60);
  const minutes = time.minutes % 60;
  return { ...time, hours, minutes };
}
export function hoursToDays(time: Time): Time {
  const days = Math.floor(time.hours / 24);
  const hours = time.hours % 24;
  return { ...time, hours, days };
}

export type SetTimer = (timer: Time) => void;
export function updateTimer(startTime: Date, setTimer: SetTimer) {
  setInterval(() => setTimer(getTime(startTime)), 1000);
}
