import { Distribution } from "./streaks";

export function getPercentage(percent: number, percentor: number): number {
  return (percentor / percent) * 100;
}

type SixNums = [number, number, number, number, number, number];

export function getPercentages(distribution: Distribution): SixNums {
  const max = distribution.reduce((p, c) => (p > c ? p : c), -10000);
  return distribution.map((n) => getPercentage(max, n)) as SixNums;
}
