import {
  addToList,
  assignColors,
  filterDuplicate,
  gl,
  onlyColors,
  onlyLetters,
  yl,
} from "./guessbot";
import { Color, GuessColor } from "./types";
import { removeDupes } from "../tests/actions/arbitraries";
import { isEqual } from "lodash";

// Points

export function greenPoints(answers: GuessColor[]): number[] {
  const points: number[] = answers.map(
    (answer) => onlyColors(answer).filter((c) => c === "🟩").length * 2
  );
  const isThereGreenQ = (guess: GuessColor) =>
    guess.some((lc) => isEqual(lc, gl("q")));
  const pointsQ: number[] = points.map((pt, idx) =>
    isThereGreenQ(answers[idx]) ? pt + 2 : pt
  );
  return pointsQ;
}
export function yellowPoints(
  answers: GuessColor[],
  pointsGreen: number[]
): number[] {
  const points: number[] = answers.map(
    (answer, idx) =>
      pointsGreen[idx] + onlyColors(answer).filter((c) => c === "🟨").length
  );
  const isThereYellowQ = (guess: GuessColor) =>
    guess.some((lc) => isEqual(lc, yl("q")));
  const pointsQ: number[] = points.map((pt, idx) =>
    isThereYellowQ(answers[idx]) ? pt + 1 : pt
  );
  return pointsQ;
}
export function addBonuses(answers: GuessColor[], points: number[]): number[] {
  const colors: Color[][] = answers.map((answer) => onlyColors(answer));
  const points5: number[] = points.map((pt, idx) =>
    colors[idx].every((c) => c !== "⬛") ? pt + 50 : pt
  );
  const points4: number[] = points5.map((pt, idx) =>
    filterDuplicate(colors[idx], "⬛").length === 1 ? pt + 30 : pt
  );
  return points4;
}

export function sortDescending(nums: number[]): number[] {
  const idx = (p: number[], c: number) => p.findIndex((n) => n <= c);
  return nums.reduce(
    (p, c) => (idx(p, c) === -1 ? [...p, c] : addToList(c, idx(p, c), p)),
    [] as number[]
  );
}
//

export function assignAnswersColors(
  guess: string,
  answers: string[]
): GuessColor[] {
  return answers.map((answer) => assignColors(answer, guess));
}

export function percentageOf(n1: number, n2: number): number {
  if (n1 < 0 || n2 < 0) {
    throw "Negative number not accepted";
  }
  if (n1 === 0 || n2 === 0) {
    throw "Zero not accepted";
  }
  return (100 / n2) * n1;
}

export function roundPercentage(percent: number): number {
  return Math.round(percent / 10);
}

export type Rank = "🏆" | "🥇" | "🥈" | "🥉" | "🤖";

export function rank(score: number): Rank {
  return score <= 5 ? "🥉" : score <= 7 ? "🥈" : score <= 9 ? "🥇" : "🏆";
}

export function makeScore(
  guess: string,
  answersStr: string[],
  answer: string
): number {
  const answerColor: GuessColor = assignColors(answer, guess);
  const ansPointsG: number[] = greenPoints([answerColor]);
  const ansPointsY: number[] = yellowPoints([answerColor], ansPointsG);
  const ansBonussed: number = addBonuses([answerColor], ansPointsY)[0];

  const answers: GuessColor[] = assignAnswersColors(guess, answersStr);

  const pointsG: number[] = greenPoints(answers);
  const pointsY: number[] = yellowPoints(answers, pointsG);
  const bonussed: number[] = addBonuses(answers, pointsY);
  const withoutDupes: number[] = removeDupes(bonussed);
  const sorted: number[] = sortDescending(withoutDupes);

  const idx: number = sorted.findIndex((point) => point === ansBonussed);

  const answerSameAsGuess: boolean = answer === guess;
  const otherAnswersAvailable: boolean = sorted.length !== 0;
  if (answerSameAsGuess && otherAnswersAvailable) {
    return 0;
  }

  const percentage: number = percentageOf(idx + 1, sorted.length);
  const integer: number = parseInt(`${percentage}`, 10);
  return roundPercentage(integer);
}
