import { delay } from "lodash";
import { SetAnimateKeyboard, SetShowKeyboard } from "../components/keyboard";

export function toggleKeyboard(
  showKeyboard: boolean,
  setShowKeyboard: SetShowKeyboard,
  setAnimateKeyboard: SetAnimateKeyboard
) {
  delay(() => setShowKeyboard(showKeyboard === false), /* 301 */ 0);
}
