import { Entry, GuessColor } from "./types";

export function getAnswerHistory(entries: Entry[]): string[] {
  return entries.reduce(
    (aHis, entry) =>
      entry._marker === "Answered" ? [entry.answer, ...aHis] : aHis,
    [] as string[]
  );
}
export function getAnsweredGuessHistory(entries: Entry[]): GuessColor[] {
  return entries.reduce(
    (gHis, entry) =>
      entry._marker === "Answered" ? [entry.guess, ...gHis] : gHis,
    [] as GuessColor[]
  );
}
export function getCurGuess(entries: Entry[]): string | undefined {
  return entries[0]._marker === "Answered" ? undefined : entries[0].guess;
}
