import { useEffect, useState } from "react";
import { answerPadding, Key, keyboard } from "../actions/keyboard";
import { keyClickedHandler, keyDownHandler } from "../actions/key-typed";
import { OnAnsweredInps } from "../actions/on-answered";
import {
  Entry,
  GameStatus,
  GuessColor,
  InpAns,
  Letter,
  LetterColor,
} from "../actions/types";
import { addToList, onlyLetters, replace } from "../actions/guessbot";
import { toggleKeyboard } from "../actions/toggle-keyboard";

export type KeyboardAction = "enter" | "delete";
type KeyboardAndActions = [Key[], (Key | KeyboardAction)[], Key[]];
export type SetShowKeyboard = (showKeyboard: boolean) => void;
export type SetAnimateKeyboard = (animateKeyboard: AnimateKeyboard) => void;
type AnimateKeyboard = "on" | "off" | null;

interface KeyboardProps {
  gHis: GuessColor[];
  gameStatus: GameStatus;
  showKeyboard: boolean;
  setShowKeyboard: SetShowKeyboard;
  onAnsweredInps: OnAnsweredInps;
  recentGuess: Entry;
}

export const Keyboard = (props: KeyboardProps) => {
  const gHis = props.gHis;
  const letters = keyboard(gHis);

  const [inpAns, setInpAns] = useState(null as InpAns);

  useEffect(() => {
    function callback(ev: any) {
      keyDownHandler(ev, inpAns, setInpAns, props.onAnsweredInps);
    }
    window.addEventListener("keydown", callback);
    return () => window.removeEventListener("keydown", callback);
  });
  const [animateKeyboard, setAnimateKeyboard] = useState(
    null as AnimateKeyboard
  );
  console.log(props.gHis, props.recentGuess);
  function shouldHighlight(kl: Letter): boolean {
    const ghisNonexistent = gHis.length === 0;
    const recentGuessIncludesL =
      props.recentGuess._marker === "Unanswered" &&
      props.recentGuess.guess.includes(kl);

    // return recentGuessIncludesL && !ghisNonexistent
    //   ? onlyLetters(gHis[gHis.length - 1]).includes(kl)
    //     ? false
    //     : true
    //   : false;
    const joinedGHis = gHis.reduce(
      (p, guess) => [...p, ...guess],
      [] as LetterColor[]
    );
    return recentGuessIncludesL
      ? ghisNonexistent
        ? true
        : !onlyLetters(joinedGHis).includes(kl)
        ? true
        : false
      : false;
  }

  function onKeyboardToggled() {
    toggleKeyboard(
      props.showKeyboard,
      props.setShowKeyboard,
      setAnimateKeyboard
    );
  }

  function onKeyClicked(l: Letter | KeyboardAction) {
    return keyClickedHandler(l, inpAns, setInpAns, props.onAnsweredInps);
  }

  const aIdx = 2; // Action index
  const enterAdded = [
    letters[0],
    letters[1],
    addToList("enter" as KeyboardAction, 0, letters[aIdx]),
  ];
  const deleteAdded = addToList(
    "delete" as KeyboardAction,
    enterAdded[aIdx].length,
    enterAdded[aIdx]
  );
  const keys = replace(deleteAdded, aIdx, letters) as KeyboardAndActions;

  return (
    <div
      className={`footer-keyboard ${
        animateKeyboard !== null ? `keyboard-animate-${animateKeyboard}` : ""
      } ${props.showKeyboard ? "" : "footer-keyboard-hidden"}`}
      hidden={props.gameStatus !== "playing"}
    >
      <div className="above-keyboard-cont">
        <div />
        <div className="ans">
          {answerPadding(inpAns).map((l, idx) => (
            <div key={`ans-letter-${idx}`} className="underline-letter">
              {l === null ? "" : l}
            </div>
          ))}
        </div>
        <div className="toggle-keyboard-cont">
          <div className="toggle-keyboard" onClick={() => onKeyboardToggled()}>
            {props.showKeyboard ? "}" : "{"}
          </div>
        </div>
      </div>
      <div className={`keyboard-cont${props.showKeyboard ? "" : "-hidden"}`}>
        <div className="keyboard">
          {keys.map((row, rowIdx) => (
            <div className="keyboard-row" key={`keyboard-row-${rowIdx}`}>
              {row.map((key, keyIdx) => (
                <div className="key-cont" key={`key-cont-${keyIdx}`}>
                  {key === "enter" ? (
                    <div
                      className="enter key"
                      onClick={() => onKeyClicked(key)}
                    >
                      &lt;
                    </div>
                  ) : key === "delete" ? (
                    <div
                      className="delete key"
                      onClick={() => onKeyClicked(key)}
                    >
                      &gt;
                    </div>
                  ) : shouldHighlight(key.letter) ? (
                    <div
                      onClick={() => onKeyClicked(key.letter)}
                      key={`keyboard-letter-${keyIdx}`}
                      className="key key-highlight"
                    >
                      {key.letter}
                    </div>
                  ) : (
                    <div
                      onClick={() => onKeyClicked(key.letter)}
                      key={`keyboard-letter-${keyIdx}`}
                      className={`key${
                        key.color === "none"
                          ? ""
                          : key.color === "🟩"
                          ? " key-green"
                          : key.color === "🟨"
                          ? " key-yellow"
                          : " key-black"
                      }`}
                    >
                      {key.letter}
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
