import React from "react";

export interface AnswerProps {
  errorMsg: ErrorMsg;
}

export type ErrorMsg = null | "Word not in list" | "Word not valid";
export type SetErrorMsg = (err: ErrorMsg) => void;

export const Answer: React.FC<AnswerProps> = ({ errorMsg }) => {
  return (
    <div>
      <div className="error-msg" hidden={errorMsg === null}>
        {errorMsg}
      </div>
    </div>
  );
};
