import { Entry } from "../actions/types";
import { AnimState } from "./render-rows";
import * as React from "react";
import { getColor } from "../actions/get-color";
import { onlyLetters } from "../actions/guessbot";

interface RenderFirstRowProps {
  entry: Entry;
  animState: AnimState;
  idIdx: number;
}

export const RenderFirstRow: React.FC<RenderFirstRowProps> = ({
  entry,
  animState,
  idIdx,
}) => {
  // console.log(entry, animState);
  return entry.guess === "waiting-for-guess" ? (
    <div>🤖 Generating guess...</div>
  ) : (
    <div
      className={`row-cont ${animState === "before" ? "unanswered" : ""}`}
      id={`entry-${idIdx}`}
    >
      <div className="row">
        <div />
        <div className="row-ans">
          {entry._marker === "Unanswered" || animState === "before"
            ? ""
            : entry.answer}
        </div>
        <div className="guess">
          {entry._marker === "Unanswered" || animState === "before"
            ? (entry._marker === "Unanswered"
                ? entry.guess.split("")
                : onlyLetters(entry.guess)
              ).map((l, idx) => (
                <div key={`unanswered-letter-${idx}`} className="item">
                  <div className="txt">{l}</div>
                </div>
              ))
            : entry.guess.map((lc, idx) => (
                <div
                  key={`answered-letter-${idx}`}
                  className={`item ${getColor(lc.color)}`}
                >
                  <div className="txt">{lc.letter}</div>
                </div>
              ))}
        </div>
        <div className="score">
          {entry._marker === "Unanswered" || animState === "before"
            ? ""
            : entry.score}
        </div>
      </div>
      {/* {(animState === "unanswered" && entry._marker === "Answered") ||
      (animState === "answered" && entry._marker === "Answered") ? (
        <div className="row">
          <div />
          <div className="row-ans">{entry.answer}</div>
          <div className="guess">
            {entry.guess.map((lc, idx) => (
              <div className="item" key={`guess-letter-${idx}`}>
                <div className="txt">{lc.letter}</div>
              </div>
            ))}
          </div>
          <div className="score">{entry.score}</div>
        </div>
      ) : animState === "colored" && entry._marker === "Answered" ? (
        <div className="row">
          <div />
          <div className="row-ans">{entry.answer}</div>
          <div className="guess">
            {entry.guess.map((lc, idx) => (
              <div
                className={`item ${
                  lc.color === "⬛"
                    ? "black"
                    : lc.color === "🟨"
                    ? "yellow"
                    : "green"
                }`}
                key={`guess-letter-${idx}`}
              >
                <div className="txt">{lc.letter}</div>
              </div>
            ))}
          </div>
          <div className="score">{entry.score}</div>
        </div>
      ) : animState === "scored" && entry._marker === "Answered" ? (
        <div className="row">
          <div />
          <div className="row-ans">{entry.answer}</div>
          <div className="guess">
            {entry.guess.map((lc, idx) => (
              <div
                className={`item ${
                  lc.color === "⬛"
                    ? "black"
                    : lc.color === "🟨"
                    ? "yellow"
                    : "green"
                }`}
                key={`guess-letter-${idx}`}
              >
                <div className="txt">{lc.letter}</div>
              </div>
            ))}
          </div>
          <div className="score">{entry.score}</div>
        </div>
      ) : animState === "coloring" && entry._marker === "Answered" ? (
        <div className="row">
          <div />
          <div className="row-ans">{entry.answer}</div>
          <div className="guess">
            {entry.guess.map((lc, idx) => (
              <div
                key={`item-${idx}`}
                className={`item ${
                  // if state is equal to idx, set the class as to-[color]
                  // if the state is less than idx, don't change the class
                  // if the state is greater than idx, set the class as [color]
                  guessAnimState === idx
                    ? "to-" + getColor(lc.color)
                    : guessAnimState === null || guessAnimState < idx
                    ? ""
                    : getColor(lc.color)
                }`}
              >
                <div className="txt">{lc.letter}</div>
              </div>
            ))}
          </div>
          <div className="score">{entry.score}</div>
        </div>
      ) : animState === "unanswered" && entry._marker === "Unanswered" ? (
        <div className="row">
          <div />
          <div />
          <div className="guess">
            {entry.guess.split("").map((l, idx) => (
              <div className="item" key={`guess-letter-${idx}`}>
                <div className="txt">{l}</div>
              </div>
            ))}
          </div>
          <div />
        </div>
      ) : (
        <div>Not supposed to come here</div>
      )} */}
    </div>
  );
};
