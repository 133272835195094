import React, { useState } from "react";
import { GameStatus, SetShowHowToPlay } from "../../actions/types";
import { EndProps, SetShowEndScreen } from "../end";
// import { SetShowEndScreen } from "./end-screen";
import { Leftbar } from "./leftbar";
import { Rightbar } from "./rightbar";

export interface AppbarProps {
  endProps: EndProps;
  showEndScreen: boolean;
  setShowEndScreen: SetShowEndScreen;
}

export const Appbar: React.FC<AppbarProps> = ({
  endProps,
  showEndScreen,
  setShowEndScreen,
}) => {
  const gameStatus = endProps.gameStatus;
  const [showHowToPlay, setShowHowToPlay] = useState(() => {
    // localStorage.getItem("allState") === null ? false : true
    return localStorage.getItem("allState") === null ? false : true;
  });

  return (
    <div className="page-header">
      <div className="appbar">
        <Leftbar
          showHowToPlay={showHowToPlay}
          setShowHowToPlay={setShowHowToPlay}
          gameStatus={gameStatus}
        />
        <div className="center-bar">
          <div className="heading">
            <img className="heading-image" src="images/title.svg" />
          </div>
        </div>
        <Rightbar
          endProps={endProps}
          showEndScreen={showEndScreen}
          setShowEndScreen={setShowEndScreen}
        />
      </div>
    </div>
  );
};
