import { Entry, GameStatus } from "../actions/types";
import * as React from "react";
import { RenderFirstRow } from "./render-first-row";
import { RenderOtherRows } from "./render-other-rows";

interface RowsProps {
  entries: Entry[];
  scores: number[];
  animState: AnimState;
  gameStatus: GameStatus;
}

export type AnimState = "before" | "after" | null;
export type SetAnimState = (animState: AnimState) => void;
export type GuessAnimState = null | 0 | 1 | 2 | 3 | 4;
export type setGuessAnimState = (guessAnimState: GuessAnimState) => void;

export const Rows: React.FC<RowsProps> = ({
  entries,
  scores,
  animState,
  gameStatus,
}) => {
  return (
    <div className="entries">
      {entries.map((entry, idx) => (
        <div
          key={`word-${idx}`}
          // className={`row-cont ${
          //   idx === 0 ? (animState === "scored" ? "" : animState) : ""
          // }`}
        >
          {idx === 0 && gameStatus !== "end" ? (
            <RenderFirstRow
              entry={entry}
              animState={animState}
              idIdx={entries.length - 1 - idx}
            />
          ) : (
            <RenderOtherRows
              idIdx={entries.length - 1 - idx}
              entries={entries}
              entry={entry}
              scores={scores}
              idx={idx}
            />
          )}
        </div>
      ))}
    </div>
  );
};
