import React from "react";
import { toggleEndScreen } from "../../actions/toggle-end-screen";
import { EndProps, SetShowEndScreen } from "../end";

export interface SummaryProps {
  endProps: EndProps;
  showEndScreen: boolean;
  setShowEndScreen: SetShowEndScreen;
}

export const Summary: React.FC<SummaryProps> = ({
  showEndScreen,
  setShowEndScreen,
}) => {
  function clickHandler() {
    toggleEndScreen(setShowEndScreen, showEndScreen);
  }
  return (
    <div>
      <div className="stats">
        <img className="icon" src="./images/stats.svg" onClick={clickHandler} />
      </div>
      {/* {showEndScreen === true ? (
        <End {...endProps} gameNotEnded={endProps.gameStatus !== "end"} />
      ) : (
        <div />
      )} */}
    </div>
  );
};
