import React from "react";
import { Entry } from "../actions/types";

interface RenderOtherRowsInps {
  entries: Entry[];
  entry: Entry;
  idx: number;
  idIdx: number;
  scores: number[];
}

export const RenderOtherRows: React.FC<RenderOtherRowsInps> = ({
  entries,
  entry,
  idx,
  idIdx,
  scores,
}) => {
  // console.log(entries, entry, idx);
  return (
    <div className="row-cont" id={`entry-${idIdx}`}>
      <div className="row">
        <div className="empty" />
        {entry._marker === "Unanswered" ? (
          <div className="empty" />
        ) : entry._marker === "Answered" ? (
          <div key={`row-ans-${idx}`} className="row-ans">
            {entry.answer}
          </div>
        ) : (
          <div />
        )}
        <div className="guess">
          {entry._marker === "Answered"
            ? entry.guess.map((lc, lcIdx) => (
                <div
                  key={`wl-${idx}-${lcIdx}}`}
                  className={`item ${
                    idx === 0
                      ? `green green-${lcIdx}`
                      : lc.color === "🟩"
                      ? "green"
                      : lc.color === "🟨"
                      ? "yellow"
                      : "black"
                  }`}
                >
                  <div className="txt">{lc.letter}</div>
                </div>
              ))
            : entry.guess.split("").map((l, lIdx) => (
                <div key={`wl-${idx}-${lIdx}`} className="item">
                  <div className="txt">{l}</div>
                </div>
              ))}
        </div>
        {entry._marker === "Answered" ? (
          entries[0]._marker === "Answered" ? (
            <div key={`score-${idx}`} className="score">
              {scores[idx]}
            </div>
          ) : (
            <div key={`score-${idx}`} className="score">
              {scores[idx - 1]}
            </div>
          )
        ) : (
          <div />
        )}
      </div>
    </div>
  );
};
