import React from "react";
import { lc } from "../../actions/guessbot";
import { howToPlay } from "../../actions/how-to-play";
import { Color, Letter, LetterColor } from "../../actions/types";
import { AppbarProps } from "./appbar";
import { LeftbarProps } from "./leftbar";

export const HowToPlay: React.FC<LeftbarProps> = ({
  showHowToPlay,
  setShowHowToPlay,
  gameStatus,
}) => {
  function onClick() {
    howToPlay(setShowHowToPlay, showHowToPlay);
  }
  const guess1 = "robot".split("") as Letter[];
  const guess1Colors: Color[] = ["⬛", "🟩", "🟨", "⬛", "🟩"];
  const guess2 = guess1.map((l, idx) => lc(l, guess1Colors[idx]));
  const guess3 = "doubt".split("") as Letter[];
  function lMarkerMaker(l: Letter): MarkerLetter {
    return { letter: l, _marker: "no-color" };
  }
  function lcMarkerMaker(lc: LetterColor): MarkerLetter {
    return { ...lc, _marker: "color" };
  }
  // const ex1: (LetterColor | Letter)[] = [lc("r", "🟩"), "o", "b", "o", "t"];
  type MarkerLetter =
    | { letter: Letter; color: Color; _marker: "color" }
    | { letter: Letter; _marker: "no-color" };

  const ex1: MarkerLetter[] = [
    lcMarkerMaker(lc("r", "🟩")),
    ...["o", "b", "o", "t"].map((l) => lMarkerMaker(l as Letter)),
  ];
  const ex2: MarkerLetter[] = [
    lMarkerMaker("r"),
    lcMarkerMaker(lc("o", "🟨")),
    ...["b", "o", "t"].map((l) => lMarkerMaker(l as Letter)),
  ];
  const ex3: MarkerLetter[] = [
    ...["r", "o", "b", "o"].map((l) => lMarkerMaker(l as Letter)),
    lcMarkerMaker(lc("t", "⬛")),
  ];
  const emojiToColor = (emoji: Color) => {
    return emoji === "⬛" ? "black" : emoji === "🟨" ? "yellow" : "green";
  };

  return (
    <div>
      <div className="how-to-play">
        <img
          className="icon"
          src="./images/how-to-play.svg"
          onClick={onClick}
          title="How to play"
        />
      </div>
      <div className="guide-wrapper" hidden={showHowToPlay}>
        <div className="container">
          <div className="modal" hidden={showHowToPlay}>
            <div className="header">
              <div />
              <h2 className="header-title">How to play Botdle</h2>
              <button className="header-close" onClick={onClick}>
                <img
                  className="close-button"
                  src="./images/close.svg"
                  alt="close"
                />
              </button>
            </div>
            <div className="modal-body">
              Botdle is{" "}
              <a href="https://www.nytimes.com/games/wordle/index.html">
                Wordle
              </a>{" "}
              with the roles reversed and no fixed answer
              <br />
              When the bot guesses, you can change your answer <br />
              Your goal is to make the bot guess for as many turns as possible!{" "}
              <br />
              <br />
              For example, the bot makes a guess:
              <div className="row-cont">
                <div className="row">
                  <div />
                  <div />
                  <div className="guess">
                    {guess1.map((l, idx) => (
                      <div key={`htp-guess1-item${idx}`} className="item">
                        <div className="txt">{l}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <br />
              Now, you think of an answer:
              <br />
              <div className="htp-ex">Boast</div>
              <br />
              Colors get assigned to the guess based on the answer:
              <div className="row-cont">
                <div className="row">
                  <div />
                  <div />
                  <div className="guess">
                    {guess2.map((lc, idx) => (
                      <div
                        key={`htp-guess2-item${idx}`}
                        className={`item ${emojiToColor(lc.color)}`}
                      >
                        <div className="txt">{lc.letter}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              The bot makes a new guess using the answer:
              <div className="row-cont">
                <div className="row">
                  <div />
                  <div />
                  <div className="guess">
                    {guess3.map((l, idx) => (
                      <div key={`htp-guess3-item${idx}`} className="item">
                        <div className="txt">{l}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              Then, you type your answer and repeat until you have to accept the
              bot&apos;s guess!
              <br />
              <br />
              <h3>How are the colors assigned?</h3>
              <br />
              <div className="row-cont">
                <div className="row">
                  <div />
                  <div />
                  <div className="guess">
                    {ex1.map((lc, idx) => (
                      <div
                        key={`htp-ex1-item${idx}`}
                        className={`item ${
                          lc._marker === "color" ? emojiToColor(lc.color) : ""
                        }`}
                      >
                        <div className="txt">{lc.letter}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <span className="htp-ex">r</span> is in the word and in the
              correct place
              <br />
              <div className="row-cont">
                <div className="row">
                  <div />
                  <div />
                  <div className="guess">
                    {ex2.map((lc, idx) => (
                      <div
                        key={`htp-ex2-item${idx}`}
                        className={`item ${
                          lc._marker === "color" ? emojiToColor(lc.color) : ""
                        }`}
                      >
                        <div className="txt">{lc.letter}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <span className="htp-ex">o</span> is in the word but in the wrong
              place
              <br />
              <div className="row-cont">
                <div className="row">
                  <div />
                  <div />
                  <div className="guess">
                    {ex3.map((lc, idx) => (
                      <div
                        key={`htp-ex3-item${idx}`}
                        className={`item ${
                          lc._marker === "color" ? emojiToColor(lc.color) : ""
                        }`}
                      >
                        <div className="txt">{lc.letter}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <span className="htp-ex">t</span> isn&apos;t in the word at all
              <br />
              <br />
            </div>
            <div className="footer">
              <button className="footer-close" onClick={onClick}>
                {gameStatus === "start" ? "Start playing" : "Continue"}
              </button>
              {/* <button className="footer-close" onClick={onClick}>
                Close
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="overlay" onClick={onClick} hidden={showHowToPlay} />
    </div>
  );
};

// Botdle is wordle with roles reversed and no fixed answer.

// When the bot guesses, you can change your answer.
// Your goal is to make the bot guess for as many turns as possible!

// For example, the bot makes a guess:
// [r][o][b][o][t]

// Now, you have to think of an answer:
// boast

// Colors get assigned to the guess based on the answer:
// [r][o][b][o][t] <- with colors

// The bot makes a new guess using the colors:
// [d][o][u][b][t]
// Then you type your answer and repeat until you have to accept the bot's guess!

// How are the colors assigned?
// [r][o][b][o][t] <- everything except r is not assigned colors
// [r] is in the word and in the correct place
// [r][o][b][o][t] <- everything except o is not assigned colors
// the first [o] is in the word but in the wrong place
// [r][o][b][o][t] <- everything except t is not assigned colors
// [t] is not in the word at all
