import React from "react";
import { onStarted } from "../actions/on-started";
import { SetGameStatus } from "../actions/types";

export interface StartProps {
  setGameStatus: SetGameStatus;
}

export const Start: React.FC<StartProps> = ({ setGameStatus }) => {
  function started() {
    onStarted(setGameStatus);
  }

  return (
    <div className="start-cont">
      <button className="start-button" onClick={started}>
        Start
      </button>
    </div>
  );
};
