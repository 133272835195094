import { isEqual } from "lodash";
import { lc, replace } from "./guessbot";
import { GuessColor, InpAns, Letter, LetterColor } from "./types";

export function flatten(list: GuessColor[]): LetterColor[] {
  return list.reduce((p, c) => [...p, ...c], [] as LetterColor[]);
}
export function removeDuplicates(list: LetterColor[]): LetterColor[] {
  return list.reduce(
    (p, c) => (p.some((lc) => isEqual(lc, c)) ? p : [...p, c]),
    [] as LetterColor[]
  );
}
export function blacks(gHis: GuessColor[]): LetterColor[] {
  const blacked = gHis.reduce(
    (p, c) => [...p, ...c.filter((lc) => lc.color === "⬛")],
    [] as LetterColor[]
  );
  return removeDuplicates(blacked);
}
export function greens(
  gHis: GuessColor[],
  blacks: LetterColor[]
): LetterColor[] {
  const flat: LetterColor[] = flatten(gHis);
  const greens: LetterColor[] = flat.filter((lc) => lc.color === "🟩");
  const merged = greens.reduce(
    (p, c) =>
      p.some((lc) => lc.color === "⬛" && lc.letter === c.letter)
        ? replace(
            c,
            p.findIndex((lc) => lc.letter === c.letter),
            p
          )
        : [...p, c],
    blacks as LetterColor[]
  );
  return removeDuplicates(merged);
}

export function idxToWord(idx: number, gHis: GuessColor[]): GuessColor {
  const gHisIdx = (idx + 1) / 5;
  return gHis[Math.trunc(gHisIdx)];
}

export function yellows(
  gHis: GuessColor[],
  greens: LetterColor[]
): LetterColor[] {
  // yellows override greens
  const flat: LetterColor[] = flatten(gHis);
  function replaceColor(
    cur: LetterColor,
    c: "🟩" | "⬛",
    idx: number
  ): boolean {
    const word = idxToWord(idx, gHis);
    const inSameWord = word.includes(lc(cur.letter, c));
    if (inSameWord) {
      return true;
    }

    const colorIdx = flat.findIndex(
      (lc) => lc.color === c && lc.letter === cur.letter
    );
    const yellowIdx = flat.findIndex(
      (lc) => lc.color === "🟨" && lc.letter === cur.letter
    );
    return yellowIdx > colorIdx;
  }

  const greenIf = (p: LetterColor[], c: LetterColor, idx: number) => {
    return p.some((lc) => lc.color === "🟩") && replaceColor(c, "🟩", idx);
  };
  const blackIf = (p: LetterColor[], c: LetterColor, idx: number) => {
    return p.some((lc) => lc.color === "⬛") && replaceColor(c, "⬛", idx);
    // Doesn't work because index might be more but still in the same word.
    // Maybe using unflattened list to check might work??
  };

  const result = flat.reduce(
    (p, c, idx) =>
      c.color === "🟨" && p.some((lc) => lc.letter === c.letter)
        ? /* p.some((lc) => lc.color === "🟩") && replaceColor(c, "🟩") */
          greenIf(p, c, idx) || blackIf(p, c, idx)
          ? replace(
              c,
              p.findIndex((lc) => lc.letter === c.letter),
              p
            )
          : p
        : [...p, c],
    greens as LetterColor[]
  );
  return removeDuplicates(result);
}
export type KeyColor = "🟩" | "🟨" | "⬛" | "none";
export interface Key {
  letter: Letter;
  color: KeyColor;
}
export function order(letters: LetterColor[]): Key[] {
  return "qwertyuiopasdfghjklzxcvbnm"
    .split("")
    .map((l) =>
      letters.findIndex((lc) => lc.letter === l) === -1
        ? { letter: l, color: "none" }
        : letters[letters.findIndex((lc) => lc.letter === l)]
    ) as Key[];
}

export type Keyboard = [Key[], Key[], Key[]];
export function splitKeys(letters: Key[]): Keyboard {
  const top: Key[] = letters.slice(0, 10);
  const home: Key[] = letters.slice(10, 19);
  const bottom: Key[] = letters.slice(19, 26);
  return [top, home, bottom];
}

export function keyboard(gHis: GuessColor[]): Keyboard {
  const blacked = blacks(gHis);
  const greened = greens(gHis, blacked);
  const yellowed = yellows(gHis, greened);
  const ordered = order(yellowed);
  return splitKeys(ordered);
}

type LetterOrNull = Letter | null;
type PaddedAnswer = [
  LetterOrNull,
  LetterOrNull,
  LetterOrNull,
  LetterOrNull,
  LetterOrNull
];
export function answerPadding(inpAns: InpAns): PaddedAnswer {
  if (inpAns === null) {
    return Array(5).fill(null) as PaddedAnswer;
  }
  const isPaddingNeeded: boolean = inpAns.length !== 5;
  return isPaddingNeeded
    ? ([
        ...inpAns.split(""),
        ...Array(5 - inpAns.length).fill(null),
      ] as PaddedAnswer)
    : (inpAns.split("") as PaddedAnswer);
}
