import { useEffect, useState } from "react";
import {
  AllState,
  EnteredAnswer,
  GameStatus,
  Letter,
  State,
} from "../actions/types";
import { SetErrorMsg } from "./answer";
import React from "react";
import { wordsSmall } from "../fives-small";
import { Start } from "./start";
import { Playing } from "./playing";
import { End } from "./end";
import { Appbar } from "./appbar/appbar";
import { Keyboard, SetShowKeyboard } from "./keyboard";
import { getAnsweredGuessHistory } from "../actions/entries";
import { AnimState, GuessAnimState } from "./render-rows";
import { getTime } from "../actions/time";
import { scrollElementIntoView } from "../actions/scroll";
import { delay } from "lodash";
import { Summary } from "../actions/streaks";

const alphabet = "abcdefghijklmnopqrstuvwxyz".split("") as Letter[];
const startTime: Date = new Date(Date.UTC(2022, 11, 22, 5, 0, 0));

const defaultState = (day: number): AllState => {
  const state: State = [alphabet, alphabet, alphabet, alphabet, alphabet];
  const length: number = wordsSmall.length - 1;
  const curGuess: string =
    day > length ? wordsSmall[day - length] : wordsSmall[day];

  return {
    state,
    wrds: wordsSmall,
    entries: [{ _marker: "Unanswered", guess: curGuess }],
  };
};
const defaultSummary: Summary = {
  daysPlayed: 0,
  maxStreak: 0,
  currentStreak: 0,
  lastPlayed: "never",
  guessDistribution: {
    distribution: [0, 0, 0, 0, 0, 0],
    current: "none",
  },
};

export const Game: React.FC = () => {
  delay(
    () =>
      localStorage.getItem("gameStatus") === "end"
        ? null
        : scrollElementIntoView(
            document.querySelector(".entries :first-child")
          ),
    40
  );
  const [gameStatus, setGameStatus] = useState(() => {
    const storedJSON = localStorage.getItem("gameStatus");
    const storedDayJSON = localStorage.getItem("day");
    return storedDayJSON === null ||
      JSON.parse(storedDayJSON) !== getTime(startTime).days
      ? ("start" as GameStatus)
      : ((storedJSON === null ? "start" : storedJSON) as GameStatus);
  });
  const [day, setDay] = useState(getTime(startTime).days);
  const [allState, setAllState] = useState(() => {
    const storedJSON = localStorage.getItem("allState");
    const storedDay = localStorage.getItem("day");
    const isNull = storedDay === null;

    return storedJSON === null || isNull
      ? defaultState(day)
      : JSON.parse(storedDay) === day
      ? JSON.parse(storedJSON)
      : defaultState(day);
  });
  const [scores, setScores] = useState(
    localStorage.getItem("scores") === null
      ? ([] as number[])
      : JSON.parse(localStorage.getItem("scores") as string)
  );
  const [vpRes, setVpRes] = useState({ width: 0, height: 0 });
  // const [inpAns, setInpAns] = useState(null as InpAns);
  const [enteredAnswer, setEnteredAnswer] = useState(null as EnteredAnswer);
  const [errorMsg, setErrorMsg] = useState(null);
  const [showKeyboard, setShowKeyboard] = useState(true);
  const [animState, setAnimState] = useState(() => {
    const stored = localStorage.getItem("animState");
    return stored === null ? ("before" as AnimState) : JSON.parse(stored);
  });
  const [summary, setSummary] = useState(() => {
    const storedJSON = localStorage.getItem("summary");
    return (
      storedJSON === null ? defaultSummary : JSON.parse(storedJSON)
    ) as Summary;
  });
  const [showEndScreen, setShowEndScreen] = useState(
    gameStatus === "end" ? true : false
  );

  useEffect(() => {
    localStorage.setItem("allState", JSON.stringify(allState));
  }, [allState]);
  useEffect(() => {
    localStorage.setItem("scores", JSON.stringify(scores));
  }, [scores]);
  useEffect(() => {
    localStorage.setItem("gameStatus", gameStatus);
    localStorage.setItem("allState", JSON.stringify(allState));
  }, [gameStatus]);
  useEffect(() => {
    localStorage.setItem("day", JSON.stringify(day));
  }, [day]);
  useEffect(() => {
    localStorage.setItem("summary", JSON.stringify(summary));
  }, [summary]);

  return (
    <div className={`${gameStatus} ${showKeyboard ? "" : "keyboard-closed"}`}>
      {/* <button onClick={() => localStorage.clear()}>Restart</button> */}
      {/* <ViewportRes vpRes={vpRes} setVpRes={setVpRes} /> */}
      <Appbar
        endProps={{
          allState,
          setAllState,
          gameStatus,
          setGameStatus,
          scores,
          setScores,
          errorMsg,
          setErrorMsg: setErrorMsg as SetErrorMsg,
          animState,
          setAnimState,
          summary,
          day,
          startTime,
          showEndScreen,
          setShowEndScreen,
        }}
        showEndScreen={showEndScreen}
        setShowEndScreen={setShowEndScreen}
      />
      {gameStatus === "start" ? (
        <Start setGameStatus={setGameStatus} />
      ) : gameStatus === "playing" ? (
        <Playing
          allState={allState}
          setAllState={setAllState}
          gameStatus={gameStatus}
          setGameStatus={setGameStatus}
          scores={scores}
          setScores={setScores}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg as SetErrorMsg}
          animState={animState}
          setAnimState={setAnimState}
        />
      ) : (
        <End
          allState={allState}
          setAllState={setAllState}
          gameStatus={gameStatus}
          setGameStatus={setGameStatus}
          scores={scores}
          setScores={setScores}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg as SetErrorMsg}
          animState={animState}
          setAnimState={setAnimState}
          summary={summary}
          day={day}
          startTime={startTime}
          showEndScreen={showEndScreen}
          setShowEndScreen={setShowEndScreen}
        />
      )}
      {showEndScreen && gameStatus !== "end" ? (
        <div>
          <End
            allState={allState}
            setAllState={setAllState}
            gameStatus={gameStatus}
            setGameStatus={setGameStatus}
            scores={scores}
            setScores={setScores}
            errorMsg={errorMsg}
            setErrorMsg={setErrorMsg as SetErrorMsg}
            animState={animState}
            setAnimState={setAnimState}
            summary={summary}
            day={day}
            startTime={startTime}
            showEndScreen={showEndScreen}
            setShowEndScreen={setShowEndScreen}
          />
        </div>
      ) : null}
      {gameStatus === "playing" ? (
        <Keyboard
          gameStatus={gameStatus as GameStatus}
          showKeyboard={showKeyboard}
          setShowKeyboard={setShowKeyboard as SetShowKeyboard}
          gHis={getAnsweredGuessHistory(allState.entries)}
          // setEnteredAnswer={setEnteredAnswer}
          onAnsweredInps={{
            enteredAnswer,
            setEnteredAnswer,
            allState,
            setAllState,
            gameStatus,
            setGameStatus,
            scoresInps: { scores, setScores },
            setErrorMsg: setErrorMsg as SetErrorMsg,
            animState,
            setAnimState,
            summary,
            setSummary,
            day,
            setShowEndScreen,
          }}
          recentGuess={allState.entries[0]}
        />
      ) : null}
    </div>
  );
};
