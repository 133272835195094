import { replace } from "./guessbot";
import { GameStatus } from "./types";

// main streaks function (returns finished streaks)
// append (returns updated streak. appends day and appends streak)
// shouldAddStreak (returns boolean)

export interface Summary {
  daysPlayed: number;
  maxStreak: number;
  currentStreak: number;
  guessDistribution: GuessDistribution;
  lastPlayed: number | "never";
}
export type DistributionIdx =
  | "none"
  | "one"
  | "two"
  | "three"
  | "four"
  | "five"
  | "six";
export type Distribution = [number, number, number, number, number, number];
export interface GuessDistribution {
  distribution: Distribution;
  current: DistributionIdx;
}

export type WhenLastPlayed = "before" | "same" | "one-day-later" | "after";

export type SetSummary = (summary: Summary) => void;

export function checkDay(summary: Summary, day: number): WhenLastPlayed {
  const result =
    summary.lastPlayed === day - 1 || summary.lastPlayed === "never"
      ? "one-day-later"
      : summary.lastPlayed === day
      ? "same"
      : summary.lastPlayed < day
      ? "before"
      : "after";
  if (result === "after") {
    throw "Error - Day does not match Last Played";
  }
  return result;
}
// eslint-disable-next-line max-params
export function getSummary(
  summary: Summary,
  day: number,
  gameStatus: GameStatus,
  guessesLength: number
): Summary {
  const checked = checkDay(summary, day);
  console.log(checked);
  if (checked === "before") {
    return clearStreak(summary, day);
  }
  if (checked === "same") {
    return summary;
  }
  return gameStatus === "end"
    ? appendStreak(summary, day, guessesLength)
    : summary;
}

export function appendStreak(
  summary: Summary,
  day: number,
  guessesLength: number
): Summary {
  const { daysPlayed, currentStreak, maxStreak } = summary;
  const streaked: Summary = {
    ...summary,
    daysPlayed: daysPlayed + 1,
    currentStreak: currentStreak + 1,
    lastPlayed: day,
  };
  const updated: Summary = distributeGuess(streaked, guessesLength);
  return updated.currentStreak > maxStreak
    ? { ...updated, maxStreak: updated.currentStreak }
    : updated;
}
export function clearStreak(summary: Summary, day: number): Summary {
  return {
    ...summary,
    lastPlayed: day,
    currentStreak: 1,
    daysPlayed: summary.daysPlayed + 1,
  };
}
export function distributeGuess(
  summary: Summary,
  guessesLength: number
): Summary {
  const gd = summary.guessDistribution;
  const idx = guessesLength - 1;
  console.log(idx);
  const r = {
    ...summary,
    guessDistribution: {
      current: translateDistributionIdx(guessesLength),
      distribution: replace(
        gd.distribution[idx > 5 ? 5 : idx] + 1,
        idx > 5 ? 5 : idx,
        gd.distribution
      ) as Distribution,
    },
  };
  return r;
}

export function translateDistributionIdx(n: number): DistributionIdx {
  return n > 6
    ? "six"
    : (["one", "two", "three", "four", "five", "six"][
        n - 1
      ] as DistributionIdx);
}
