import React, { useState } from "react";
import { getPercentages } from "../actions/distribution-percentage";
import { getAnsweredGuessHistory } from "../actions/entries";
import { share } from "../actions/share";
import { GuessDistribution, Summary } from "../actions/streaks";
import { toggleEndScreen } from "../actions/toggle-end-screen";
import {
  AllState,
  Answered,
  GameStatus,
  SetAllState,
  SetGameStatus,
  SetScores,
} from "../actions/types";
import { ErrorMsg, SetErrorMsg } from "./answer";
import { Playing } from "./playing";
import { AnimState, SetAnimState } from "./render-rows";
import { Timer } from "./timer";

export interface EndProps {
  allState: AllState;
  setAllState: SetAllState;
  gameStatus: GameStatus;
  setGameStatus: SetGameStatus;
  scores: number[];
  setScores: SetScores;
  errorMsg: ErrorMsg;
  setErrorMsg: SetErrorMsg;
  animState: AnimState;
  setAnimState: SetAnimState;
  summary: Summary;
  day: number;
  startTime: Date;
  showEndScreen: boolean;
  setShowEndScreen: SetShowEndScreen;
}

export type SetShowEndScreen = (showEndScreen: boolean) => void;
export type ShareButton = "Copy" | "Copied!";
export type SetShareButton = (sb: ShareButton) => void;

export const End: React.FC<EndProps> = ({
  allState,
  setAllState,
  gameStatus,
  setGameStatus,
  scores,
  setScores,
  errorMsg,
  setErrorMsg,
  animState,
  setAnimState,
  summary,
  day,
  startTime,
  showEndScreen,
  setShowEndScreen,
}) => {
  const gameNotEnded = gameStatus !== "end";
  const [shareButton, setShareButton] = useState("Copy" as ShareButton);

  function onClick() {
    toggleEndScreen(setShowEndScreen, showEndScreen);
  }
  function onShare() {
    share(
      { entries: allState.entries as Answered[], scores, day },
      setShareButton
    );
  }

  const gd: GuessDistribution = summary.guessDistribution;
  const percentages = getPercentages(gd.distribution);

  return (
    <div>
      {gameNotEnded || showEndScreen === true ? (
        <div />
      ) : (
        <Playing
          allState={allState}
          setAllState={setAllState}
          gameStatus={gameStatus}
          setGameStatus={setGameStatus}
          scores={scores}
          setScores={setScores}
          errorMsg={errorMsg}
          setErrorMsg={setErrorMsg}
          animState={animState}
          setAnimState={setAnimState}
        />
      )}
      <div className="end-container" hidden={!showEndScreen}>
        <div className="end-modal modal">
          <div className="header">
            <div />
            <h2>Statistics</h2>
            <button className="header-close" onClick={onClick}>
              <img
                className="close-button"
                src="./images/close.svg"
                alt="close"
              />
            </button>
          </div>
          <div className="end-contents">
            <div className="tries">
              The bot guessed the word in{" "}
              {getAnsweredGuessHistory(allState.entries).length} tries!
            </div>
            <div className="summary-stats">
              <div className="streaks-cont">
                <div className="days-played streak">
                  <div className="streak-num">{summary.daysPlayed}</div>
                  <div className="streak-label">Days played</div>
                </div>
                <div className="cur-streak streak">
                  <div className="streak-num">{summary.currentStreak}</div>
                  <div className="streak-label">Current streak</div>
                </div>
                <div className="max-streak streak">
                  <div className="streak-num">{summary.maxStreak}</div>
                  <div className="streak-label">Max streak</div>
                </div>
              </div>
              <div className="dist-cont">
                {gd.distribution.map((n, idx) => (
                  <div
                    key={`distribution-idx-${idx}`}
                    className={`dist-row ${
                      allState.entries.length === idx + 1 ? "highlight" : ""
                    }`}
                  >
                    <span className="dist-num">{idx + 1}</span>
                    <div className="dist-percent-cont">
                      <div
                        className="dist"
                        style={{
                          width: `${
                            percentages[idx] === 0 ? 5 : percentages[idx]
                          }%`,
                        }}
                      >
                        {n}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {gameNotEnded === true ? <div /> : <Timer startTime={startTime} />}
            <div className="footer">
              <button
                className="share-button"
                onClick={onShare}
                hidden={gameNotEnded}
              >
                {shareButton}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="end-overlay" onClick={onClick} hidden={!showEndScreen} />
    </div>
  );
};
