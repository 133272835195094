import React from "react";
import {
  AllState,
  GameStatus,
  SetAllState,
  SetGameStatus,
  SetScores,
} from "../actions/types";
import { Answer, ErrorMsg, SetErrorMsg } from "./answer";
import { Guess } from "./guess";
import { AnimState, SetAnimState } from "./render-rows";

export interface PlayingProps {
  allState: AllState;
  setAllState: SetAllState;
  gameStatus: GameStatus;
  setGameStatus: SetGameStatus;
  scores: number[];
  setScores: SetScores;
  errorMsg: ErrorMsg;
  setErrorMsg: SetErrorMsg;
  animState: AnimState;
  setAnimState: SetAnimState;
}

export const Playing: React.FC<PlayingProps> = (props) => (
  // <div className="play-area-cont">
  <div className="play-area">
    <Answer {...props} />
    <Guess
      allState={props.allState}
      scores={props.scores}
      animState={props.animState}
      gameStatus={props.gameStatus}
    />
  </div>
  // </div>
);
