import React from "react";
import { GameStatus, SetShowHowToPlay } from "../../actions/types";
import { AppbarProps } from "./appbar";
import { HowToPlay } from "./how-to-play";

export interface LeftbarProps {
  gameStatus: GameStatus;
  showHowToPlay: boolean;
  setShowHowToPlay: SetShowHowToPlay;
}

export const Leftbar: React.FC<LeftbarProps> = ({
  gameStatus,
  showHowToPlay,
  setShowHowToPlay,
}) => {
  return (
    <div className="left-bar">
      <HowToPlay
        showHowToPlay={showHowToPlay}
        setShowHowToPlay={setShowHowToPlay}
        gameStatus={gameStatus}
      />
    </div>
  );
};
