import { delay } from "lodash";
import { SetErrorMsg } from "../components/answer";
import { SetShowEndScreen } from "../components/end";
import { AnimState, SetAnimState } from "../components/render-rows";
import { wordsSmall } from "../fives-small";
import { getCurGuess } from "./entries";
import {
  assignColors,
  filterWords,
  generateGuess,
  onlyLetters,
  replace,
  updateState,
} from "./guessbot";
import { makeScore } from "./score";
import { scrollElementIntoView } from "./scroll";
import { getSummary, SetSummary, Summary } from "./streaks";
import {
  AllState,
  GuessColor,
  State,
  Scores,
  Entry,
  Answered,
  Unanswered,
  SetAllState,
  SetGameStatus,
  GameStatus,
  EnteredAnswer,
  SetEnteredAnswer,
} from "./types";
import { animateOnAnswered } from "./update-anim-state";

export interface OnAnsweredInps {
  enteredAnswer: EnteredAnswer;
  setEnteredAnswer: SetEnteredAnswer;
  allState: AllState;
  setAllState: SetAllState;
  gameStatus: GameStatus;
  setGameStatus: SetGameStatus;
  scoresInps: Scores;
  setErrorMsg: SetErrorMsg;
  animState: AnimState;
  setAnimState: SetAnimState;
  summary: Summary;
  setSummary: SetSummary;
  day: number;
  setShowEndScreen: SetShowEndScreen;
}

export function onAnswered(inps: OnAnsweredInps) {
  const { enteredAnswer, allState, scoresInps, setAllState } = inps;
  const { state, wrds, entries } = allState;
  const { scores, setScores } = scoresInps;

  if (enteredAnswer === null || enteredAnswer.length !== 5) {
    return "";
  }
  const value: string = enteredAnswer.toLowerCase();
  const answerInvalid = !wrds.includes(value);
  if (answerInvalid) {
    inps.setErrorMsg(
      wordsSmall.includes(enteredAnswer) ? "Word not valid" : "Word not in list"
    );
    delay(inps.setErrorMsg, 1000, null);
    return allState;
  }
  inps.setAnimState("before");
  const curGuess = getCurGuess(entries);
  const colorsAssigned: GuessColor = assignColors(curGuess as string, value);

  const newScore: number = makeScore(
    onlyLetters(colorsAssigned).join(""),
    wrds,
    value
  );

  const updatedEntry: Answered = {
    answer: value,
    guess: colorsAssigned,
    score: newScore,
    _marker: "Answered",
  };
  const updatedEntries: Entry[] = replace(updatedEntry, 0, entries);
  setScores([newScore, ...scores]);

  function onGameOverAnimsFinished() {
    setAllState({
      entries: updatedEntries,
      state: [[], [], [], [], []],
      wrds: [],
    });
    const s = getSummary(inps.summary, inps.day, "end", updatedEntries.length);
    inps.setSummary(s);
    inps.setShowEndScreen(true);
    inps.setGameStatus("end");
  }

  const guessCorrect: boolean = colorsAssigned.every((lc) => lc.color === "🟩");
  if (guessCorrect) {
    const elId = `entry-${entries.length - 1}`;
    animateOnAnswered(
      updatedEntry,
      document.querySelector(`#${elId} .row`) as HTMLElement,
      onGameOverAnimsFinished
    );
    return;
  }
  const updState: State = updateState(state, colorsAssigned);
  const newWrds: string[] = filterWords(wrds, updState, colorsAssigned);

  setAllState({
    entries: updatedEntries,
    state: updState,
    wrds: newWrds,
  });

  // -----------------------------------ON ANIMATION FINISHED FUNC----------------------------------------------------
  function onAllAnimsFinished() {
    inps.setAnimState("after");
    setAllState({
      entries: [
        { _marker: "Unanswered", guess: "waiting-for-guess" },
        ...updatedEntries,
      ],
      state: updState,
      wrds: newWrds,
    });
    //
    const newEntry: Unanswered = {
      _marker: "Unanswered",
      guess: generateGuess(newWrds, updState, colorsAssigned),
    };
    scrollElementIntoView(document.querySelector(".entries :first-child"));
    delay(
      () =>
        setAllState({
          entries: [newEntry, ...updatedEntries],
          state: updState,
          wrds: newWrds,
        }),
      1300
    );
  }
  // --------------------------------------------------------------------------------------------------

  inps.setAnimState("before");
  const elId = `entry-${entries.length - 1}`;
  animateOnAnswered(
    updatedEntry,
    document.querySelector(`#${elId} .row`) as HTMLElement,
    // document.getElementById(`entry-${entries.length - 1}`) as HTMLElement,
    onAllAnimsFinished
  );
}
