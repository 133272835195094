import { AnimState, GuessAnimState, Rows } from "./render-rows";
import React from "react";
import { AllState, GameStatus } from "../actions/types";

export interface GuessProps {
  allState: AllState;
  scores: number[];
  animState: AnimState;
  gameStatus: GameStatus;
}

export const Guess: React.FC<GuessProps> = ({
  allState,
  scores,
  animState,
  gameStatus,
}) => {
  return (
    <Rows
      entries={allState.entries}
      scores={scores}
      animState={animState}
      gameStatus={gameStatus}
    />
  );
};
