import { delay } from "lodash";
import { KeyboardAction } from "../components/keyboard";
import { onAnswered, OnAnsweredInps } from "./on-answered";
import { InpAns, Letter, SetInpAns } from "./types";

const alphabet: string[] =
  "qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM".split("");

// eslint-disable-next-line max-params
export function keyDownHandler(
  ev: any,
  inpAns: InpAns,
  setInpAns: SetInpAns,
  onAnsweredInps: OnAnsweredInps
) {
  const key = ev.key;
  if (alphabet.includes(key)) {
    setInpAns(
      inpAns === null ? key : inpAns.length === 5 ? inpAns : inpAns + key
    );
  } else {
    if (key === "Enter") {
      if (inpAns?.length === 5) {
        delay(
          () =>
            setInpAns(
              onAnsweredInps.allState.wrds.includes(inpAns) ? null : inpAns
            ),
          50
        );
        delay(
          () => onAnswered({ ...onAnsweredInps, enteredAnswer: inpAns }),
          70
        );
        return null;
      }
      return null;
    }
    setInpAns(
      inpAns === null
        ? null
        : key === "Backspace"
        ? inpAns.split("").slice(0, -1).join("")
        : inpAns
    );
  }
}

// eslint-disable-next-line max-params
export function keyClickedHandler(
  key: Letter | KeyboardAction,
  inpAns: InpAns,
  setInpAns: SetInpAns,
  onAnsweredInps: OnAnsweredInps
) {
  if (alphabet.includes(key)) {
    setInpAns(
      inpAns === null ? key : inpAns.length === 5 ? inpAns : inpAns + key
    );
    return null;
  }
  if (key === "delete") {
    setInpAns(
      inpAns === null ? inpAns : inpAns.split("").slice(0, -1).join("")
    );
    return null;
  }
  if (key === "enter") {
    setInpAns(inpAns?.length === 5 ? null : inpAns);
    delay(() => onAnswered({ ...onAnsweredInps, enteredAnswer: inpAns }), 70);
  }
}
