import { SetShareButton } from "../components/end";
import { onlyColors, onlyLetters } from "./guessbot";
import { Rank, rank } from "./score";
import { Answered, Color } from "./types";

interface ShareInps {
  entries: Answered[];
  scores: number[];
  day: number;
}

export function share(inps: ShareInps, setShareButton: SetShareButton) {
  setShareButton("Copied!");

  const { entries, scores, day } = inps;
  const colors: Color[][] = entries.map((entry) => onlyColors(entry.guess));
  const ranks: Rank[] = scores.map((score) => rank(score));
  const guesses = colors.reduce(
    (prev, cur, idx) =>
      prev + `${cur.join("")} ${idx === 0 ? "🤖" : ranks[idx]}\n`,
    ""
  );
  const copy: string =
    `I finished Botdle day ${day} in ${entries.length} ${
      entries.length === 1 ? "try" : "tries"
    }!\n\n` +
    guesses +
    `\nToday's word: ${onlyLetters(entries[entries.length - 1].guess).join(
      ""
    )}\n` +
    "\nhttps://botdle.kavpix.com/";
  if (navigator.clipboard !== undefined) {
    navigator.clipboard.writeText(copy);
  }
  const textArea = document.createElement("textarea");
  textArea.textContent = copy;
  textArea.style.position = "fixed";
  document.body.appendChild(textArea);
  //   textArea.focus();
  textArea.select();
  try {
    return document.execCommand("copy");
  } catch (err) {
    console.warn("Unable to copy to clipboard", err);
    return false;
  } finally {
    textArea.remove();
  }
}
