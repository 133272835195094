import * as fc from "fast-check";
// import { randIntBelow } from "../../actions/guessbot";
import {
  Letter,
  Color,
  State,
  Colors,
  LetterColor,
  GuessColor,
  GameStatus,
} from "../../actions/types";
import { words } from "../../fives";
import prand from "pure-rand";
import { gl, lc, onlyLetters, replace, yl } from "../../actions/guessbot";
import {
  Distribution,
  DistributionIdx,
  GuessDistribution,
  Summary,
} from "../../actions/streaks";

export const alphabet = "abcdefghijklmnopqrstuvwxyz";
const colors = ["🟩", "🟨", "⬛"] as Color[];

export function arbNumber(min: number, max: number): fc.Arbitrary<number> {
  return fc.integer({ max: max, min: min });
}
export function arbNumArray(
  lengthLimits: LengthLimits,
  valueLimits: ValueLimits
): fc.Arbitrary<number[]> {
  const { minVal, maxVal } = valueLimits;
  const { minL, maxL } = lengthLimits;
  const arbN: fc.Arbitrary<number> = arbNumber(minVal, maxVal);
  return fc.array(arbN, { minLength: minL, maxLength: maxL });
}
export function randomNumber(below: number): number {
  return Math.floor(Math.random() * below);
}

export function arbString(minL: number, maxL: number): fc.Arbitrary<string> {
  return fc.stringOf(fc.constantFrom(...alphabet.split("")), {
    minLength: minL,
    maxLength: maxL,
  });
}

export function arbColors(minL: number, maxL: number): fc.Arbitrary<Color[]> {
  return fc.array(arbColor(), {
    minLength: minL,
    maxLength: maxL,
  }) as fc.Arbitrary<Color[]>;
}
export function arbColor(): fc.Arbitrary<Color> {
  return fc.constantFrom(...colors);
}

export function removeDupes(inp: any[]): any[] {
  return Array.from(new Set(inp));
}

export function stateArb(): fc.Arbitrary<State> {
  return fc.array(stateElement(), {
    minLength: 5,
    maxLength: 5,
  }) as fc.Arbitrary<State>;
}
export function stateElement(): fc.Arbitrary<Letter[]> {
  return arbString(1, 100).map((s) => removeDupes(s.split("")));
}

export function guessArb(state: State): fc.Arbitrary<Letter[]> {
  const result: fc.Arbitrary<Letter>[] = state.map(generateLetterArb);
  return joinArbs(result);
}
export function generateLetterArb(stateElem: string[]): fc.Arbitrary<Letter> {
  return fc.stringOf(fc.constantFrom(...stateElem), {
    minLength: 1,
    maxLength: 1,
  }) as fc.Arbitrary<Letter>;
}

export function joinArbs<T>(list: fc.Arbitrary<T>[]): fc.Arbitrary<T[]> {
  const initial = fc.array(fc.constant(undefined), {
    minLength: 0,
    maxLength: 0,
  }) as unknown as fc.Arbitrary<T[]>;
  return list.reduce(
    (joined, cur) =>
      joined.chain((vals) => cur.chain((val) => fc.constant([...vals, val]))),
    initial
  );
}

export function colorsArb(state: State): fc.Arbitrary<Colors> {
  // If there is only one letter in the state, assign it green
  // Else yellow or black in random?
  // return state.map(se => se.length === 1 ? "🟩" as Color: ["⬛", "🟨", "🟩"][randIntBelow(3)] as Color);
  return joinArbs(
    state.map((se) => (se.length === 1 ? fc.constant("🟩") : arbColor()))
  ) as fc.Arbitrary<Colors>;
}

export function sgc(): fc.Arbitrary<SGC> {
  return stateArb().chain((sVal) =>
    guessArb(sVal).chain((gVal) =>
      colorsArb(sVal).chain((cVal) =>
        fc.constant({ state: sVal, guess: gVal, color: cVal })
      )
    )
  );
}
export function arb5Word(): fc.Arbitrary<string> {
  return fc
    .integer({ min: 0, max: 8886 })
    .chain((idx) => fc.constant(words[idx]));
}
export function arb5Words(min: number, max: number): fc.Arbitrary<string[]> {
  const uniqueArr: fc.Arbitrary<number[]> = fc.uniqueArray(
    fc.integer({ min: 0, max: 8886 }),
    {
      minLength: min,
      maxLength: max,
    }
  );
  return uniqueArr.chain((val) => fc.constant(val.map((idx) => words[idx])));
}

export function wordAndColor(length: number): fc.Arbitrary<WordAndColor> {
  const arbStr: fc.Arbitrary<string> = arbString(length, length);
  const arbColor: fc.Arbitrary<Color[]> = arbColors(length, length);
  return arbStr.chain((sVal) =>
    arbColor.chain((cVal) => fc.constant({ word: sVal, color: cVal }))
  );
}
export function generateRandLetterColor(): fc.Arbitrary<LetterColor> {
  const arbLetter = arbString(1, 1) as fc.Arbitrary<Letter>;
  const arbColor: fc.Arbitrary<Color[]> = arbColors(1, 1);
  return arbLetter.chain((letter) =>
    arbColor.chain((colorArr) => fc.constant(lc(letter, colorArr[0])))
  );
}
export function generateRandGuessColor(): fc.Arbitrary<GuessColor> {
  const arbLC: fc.Arbitrary<LetterColor> = generateRandLetterColor();
  const arbLCArr = fc.array(arbLC, {
    minLength: 5,
    maxLength: 5,
  }) as fc.Arbitrary<GuessColor>;
  return arbLCArr;
}
export function generateRandGreenQGuess(): fc.Arbitrary<GuessColor> {
  const arbGC: fc.Arbitrary<GuessColor> = generateRandGuessColor();
  return arbGC.chain((gc) =>
    fc
      .integer({ min: 0, max: 4 })
      .chain((idx) => fc.constant(replace(gl("q"), idx, gc) as GuessColor))
  );
}
export function generateRandNoQLetterColor(): fc.Arbitrary<LetterColor> {
  const arbL = fc.stringOf(
    fc.constantFrom(..."abcdefghijklmnoprstuvwxyz".split("")),
    { minLength: 1, maxLength: 1 }
  ) as fc.Arbitrary<Letter>;
  const arbC = fc.stringOf(fc.constantFrom("🟩", "⬛", "🟨"), {
    minLength: 1,
    maxLength: 1,
  }) as fc.Arbitrary<Color>;
  return arbL.chain((l) => arbC.chain((c) => fc.constant(lc(l, c))));
}
export function generateRandNoGreenQGuessColor(): fc.Arbitrary<GuessColor> {
  const arbGC: fc.Arbitrary<GuessColor> = generateRandGuessColor();
  const arbLC: fc.Arbitrary<LetterColor> = generateRandNoQLetterColor();
  return arbGC.chain((gc) =>
    arbLC.chain((lc) =>
      fc.constant(
        onlyLetters(gc).includes("q")
          ? (replace(
              lc,
              gc.findIndex((lc) => lc.letter === "q"),
              gc
            ) as GuessColor)
          : gc
      )
    )
  );
}
export function generateRandYellowQGuess(): fc.Arbitrary<GuessColor> {
  const arbGC: fc.Arbitrary<GuessColor> = generateRandGuessColor();
  return arbGC.chain((gc) =>
    fc
      .integer({ min: 0, max: 4 })
      .chain((idx) => fc.constant(replace(yl("q"), idx, gc) as GuessColor))
  );
}
export function generateRandGuessColors(
  minL: number,
  maxL: number
): fc.Arbitrary<GuessColor[]> {
  const arbGC: fc.Arbitrary<GuessColor> = generateRandGuessColor();
  return fc.array(arbGC, { minLength: minL, maxLength: maxL });
}

export function generateDate(): fc.Arbitrary<Date> {
  const year: fc.Arbitrary<number> = fc.integer({ min: 1971, max: 2021 });
  const month: fc.Arbitrary<number> = fc.integer({ min: 0, max: 11 });
  const day: fc.Arbitrary<number> = fc.integer({ min: 1, max: 31 });
  const hr: fc.Arbitrary<number> = fc.integer({ min: 0, max: 23 });
  const minAndSec: fc.Arbitrary<number> = fc.integer({ min: 0, max: 59 });
  return year.chain((y) =>
    month.chain((m) =>
      day.chain((d) =>
        // eslint-disable-next-line max-nested-callbacks
        hr.chain((h) =>
          // eslint-disable-next-line max-nested-callbacks
          minAndSec.chain((min) =>
            // eslint-disable-next-line max-nested-callbacks
            minAndSec.chain((s) =>
              fc.constant(new Date(Date.UTC(y, m, d, h, min, s, 0)))
            )
          )
        )
      )
    )
  );
}

export function generateGuessDistribution(
  daysPlayed: number
): fc.Arbitrary<GuessDistribution> {
  if (daysPlayed === 0) {
    return fc.constant({ distribution: [0, 0, 0, 0, 0, 0], current: "none" });
  }
  const n: fc.Arbitrary<number> = fc.integer({ min: 0, max: daysPlayed });
  const list: fc.Arbitrary<number>[] = Array(6).fill(n);
  const joined: fc.Arbitrary<number[]> = joinArbs(list);
  return joined.chain((distribution) =>
    fc.integer({ min: 0, max: 5 }).chain((idx) =>
      fc.constant({
        distribution: distribution as Distribution,
        current: ["one", "two", "three", "four", "five", "six"][
          idx
        ] as DistributionIdx,
      })
    )
  );
}

export function generateSummary(): fc.Arbitrary<Summary> {
  const min0 = fc.integer({ min: 0, max: 10000 });
  const int = (min: number, max: number) => fc.integer({ min: min, max: max });

  return min0.chain((daysPlayed) =>
    int(0, daysPlayed).chain((maxStreak) =>
      int(0, maxStreak).chain((currentStreak) =>
        // eslint-disable-next-line max-nested-callbacks
        fc
          .integer({
            min: daysPlayed,
            max: daysPlayed === 0 ? 0 : 100000,
          })
          // eslint-disable-next-line max-nested-callbacks
          .chain((lastPlayed) =>
            // eslint-disable-next-line max-nested-callbacks
            generateGuessDistribution(daysPlayed).chain((guessDistribution) =>
              fc.constant({
                daysPlayed,
                maxStreak,
                currentStreak,
                lastPlayed,
                guessDistribution,
              })
            )
          )
      )
    )
  );
}

export function generateGameStatus(): fc.Arbitrary<GameStatus> {
  return fc.constantFrom(...(["start", "end", "playing"] as GameStatus[]));
}

export interface SGC {
  state: State;
  guess: Letter[];
  color: Colors;
}

interface LengthLimits {
  // For arbNumArray function
  minL: number;
  maxL: number;
}

interface ValueLimits {
  // For arbNumArray function
  minVal: number;
  maxVal: number;
}

export interface WordAndColor {
  word: string;
  color: Color[];
}
