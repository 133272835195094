import {
  Letter,
  Color,
  LetterColor,
  State,
  GuessColor,
  GuessAndAnswer,
  SLI,
} from "./types";

// -----------------------------------------------
// Finding a guess using state

export function filterWordsState(ws: string[], state: State): string[] {
  // ws = short form for words
  return ws.filter((w) =>
    w.split("").every((l, i) => state[i].includes(l as Letter))
  );
}

export function filterWordsYellows(
  filtWords: string[],
  prevGuess: GuessColor
): string[] {
  const yellows: LetterColor[] = prevGuess.filter((lc) => lc.color === "🟨");
  return yellows.reduce(
    (p, c) => p.filter((w) => w.split("").some((l) => l === c.letter)),
    filtWords
  );
}

export function filterWords(
  w: string[],
  state: State,
  prevGuess: GuessColor | undefined
): string[] {
  if (!prevGuess) {
    return w;
  }
  const filtState: string[] = filterWordsState(w, state);
  return filterWordsYellows(filtState, prevGuess);
}

export function randIntBelow(max: number) {
  return Math.floor(Math.random() * max);
}

export function generateGuess(
  ws: string[],
  state: State,
  prevGuess: GuessColor | undefined
): string {
  if (!prevGuess) {
    return ws[randIntBelow(ws.length)];
  }
  const guesses: string[] = filterWords(ws, state, prevGuess);
  if (guesses.length > 0) {
    return guesses[randIntBelow(guesses.length)];
  }
  throw "No possible guess";
  // return guesses.length > 0
  //   ? guesses[randIntBelow(guesses.length)]
  //   : "No possible guess";
}

// --------------------------------------------------------------------------------------------------
// Updating the state based on color

export function green(sli: SLI): State {
  const { state, letter, index } = sli;
  return [
    ...state.slice(0, index),
    [letter],
    ...state.slice(index + 1),
  ] as State;
}
export function yellow(sli: SLI): State {
  const { state, letter, index } = sli;
  const letterIndex: number = state[index].findIndex((l) => l === letter);
  const letterRemoved: string[] = removeFromList(letterIndex, state[index]);

  return [
    ...state.slice(0, index),
    letterRemoved,
    ...state.slice(index + 1),
  ] as State;
}

export function black(sli: SLI, guess: GuessColor): State {
  const { state, letter, index } = sli;
  const duplicates = filterDuplicate(onlyLetters(guess), letter);
  if (duplicates.length === 1) {
    // const indexed: number[] = state.map((pos) =>
    //   pos.findIndex((l) => l === letter)
    // );
    // const removed: State = state.map((pos, i) =>
    //   removeFromList(indexed[i], pos)
    // ) as State;
    // return removed;

    const indexes: number[] = state.map((se) =>
      se.findIndex((l) => l === letter)
    );
    const removedFromState = state.map((se, i) =>
      se.length === 1
        ? se
        : indexes[i] === -1
        ? se
        : removeFromList(indexes[i], se)
    ) as State;
    return removedFromState;
  }

  const lcDuplicates = [
    ...guess.slice(0, index),
    ...guess.slice(index + 1),
  ].filter((lc) => lc.letter === letter);
  const colorDuplicates = onlyColors(lcDuplicates);

  if (colorDuplicates.includes("🟨")) {
    const letterIndex = state[index].findIndex((l) => l === letter);
    const removed: string[] = removeFromList(letterIndex, state[index]);
    return [
      ...state.slice(0, index),
      removed,
      ...state.slice(index + 1),
    ] as State;
  }
  const removed: State = state.map((pos) =>
    pos.length === 1
      ? pos
      : pos.findIndex((l) => l === letter) === -1
      ? pos
      : removeFromList(
          pos.findIndex((l) => l === letter),
          pos
        )
  ) as State;
  return removed;
}

export function updateLetter(
  prev: State,
  index: number,
  guess: GuessColor
): State {
  const letterColor: LetterColor = guess[index];
  const color: Color = onlyColor(letterColor);
  const letter: Letter = onlyLetter(letterColor) as Letter;

  if (color === "🟩") {
    const r = green(makeSli(prev, letter, index));
    return r;
  }
  if (color === "⬛") {
    const r = black(makeSli(prev, letter, index), guess);
    return r;
  }
  const result: State = yellow(makeSli(prev, letter, index));
  return result;
  // return color === "🟩" ? green(prev, letter, index) : color === "⬛" ? black(prev, letter, guess, index) : yellow(prev, letter, index)
}

export function updateState(prev: State, cur: GuessColor): State {
  const result: State = cur.reduce(
    (p, c, ind) => updateLetter(p, ind, cur),
    prev
  );
  return result;
}

// -------------------------------------------------------------------------------------------------------
// Generating the colors of the guess based on the answer

// Function that assigns each letter a green/black (in guess *and* answer)
// Function that assigns each letter a yellow/black (in guess *and* answer)
//  - Checks if all of the letter has already been assigned in the answer
//      - Yes: assign the letter as black (default)
//      - No: assign the letter yellow
// Blacks get assigned automatically in the process

export function assignGreenLetter(
  ga: GuessAndAnswer,
  idx: number
): GuessAndAnswer {
  if (ga.guess[idx].letter === ga.answer[idx].letter) {
    const replacedG: GuessColor = replace(
      gl(ga.guess[idx].letter),
      idx,
      ga.guess
    ) as GuessColor;
    const replacedA: GuessColor = replace(
      gl(ga.answer[idx].letter),
      idx,
      ga.answer
    ) as GuessColor;
    return { guess: replacedG, answer: replacedA };
  }
  return ga;
}

export function callGreens(ga: GuessAndAnswer): GuessAndAnswer {
  return [0, 1, 2, 3, 4].reduce(
    (prev, cur) => assignGreenLetter(prev, cur),
    ga
  );
}

export function assignYellowLetter(
  ga: GuessAndAnswer,
  idx: number
): GuessAndAnswer {
  if (ga.guess[idx].color === "🟩") {
    return ga;
  }
  const blacks: LetterColor[] = ga.answer.filter((lc) => lc.color === "⬛");
  const guessIdxAlreadyAssignedInAns = !onlyLetters(blacks).includes(
    ga.guess[idx].letter
  );
  if (guessIdxAlreadyAssignedInAns) {
    return ga;
  }
  const replacedG = replace(
    yl(ga.guess[idx].letter),
    idx,
    ga.guess
  ) as GuessColor;
  const answerIdx: number = ga.answer.findIndex(
    (lc) =>
      lc.letter === ga.guess[idx].letter && lc.color === ga.guess[idx].color
  );
  const replacedA = replace(
    yl(ga.answer[answerIdx].letter),
    answerIdx,
    ga.answer
  ) as GuessColor;
  return { guess: replacedG, answer: replacedA };
}
export function callYellows(ga: GuessAndAnswer): GuessAndAnswer {
  return [0, 1, 2, 3, 4].reduce(
    (prev, cur) => assignYellowLetter(prev, cur),
    ga
  );
}
export function assignColors(guessStr: string, answerStr: string): GuessColor {
  const guess: GuessColor = makeWordColor(guessStr, "⬛");
  const answer: GuessColor = makeWordColor(answerStr, "⬛");
  const greens = callGreens({ guess: guess, answer: answer });
  const yellows = callYellows(greens);
  return yellows.guess;
}

// ----------------------------------------------------------------------------------------------
// Other functions

export function intersperseLC(word: Letter[], color: Color[]): LetterColor[] {
  return word.map((letter, i) => lc(letter, color[i]));
}

export function onlyLetters(word: LetterColor[]): Letter[] {
  return word.map(onlyLetter);
}
export function onlyLetter(lc: LetterColor): Letter {
  return lc.letter;
}
export function onlyColors(letters: LetterColor[]): Color[] {
  return letters.map(onlyColor);
}
export function onlyColor(lc: LetterColor): Color {
  return lc.color;
}

export function lc(letter: Letter, color: Color): LetterColor {
  return { letter, color };
}
export function yl(letter: Letter): LetterColor {
  return lc(letter, "🟨");
}
export function bl(letter: Letter): LetterColor {
  return lc(letter, "⬛");
}
export function gl(letter: Letter): LetterColor {
  return lc(letter, "🟩");
}

export function makeSubWordColor(word: string, color: Color): LetterColor[] {
  return word.split("").map((letter) => lc(letter as Letter, color));
}
export function makeWordColor(word: string, color: Color): GuessColor {
  return makeSubWordColor(word, color) as GuessColor;
}
export function filterDuplicate(str: any[], item: any): any[] {
  return str.filter((l) => l === item);
}
export function removeFromList(idx: number, list: any[]): any[] {
  return [...list.slice(0, idx), ...list.slice(idx + 1)];
}
export function addToList(item: any, idx: number, list: any[]): any[] {
  return [...list.slice(0, idx), item, ...list.slice(idx)];
}
export function replace(item: any, idx: number, list: any[]) {
  return addToList(item, idx, removeFromList(idx, list));
}

export function makeSli(s: State, l: Letter, i: number): SLI {
  return { state: s, letter: l, index: i };
}
