import React from "react";
import { EndProps } from "../end";
import { Summary, SummaryProps } from "./end-screen";

export const Rightbar: React.FC<SummaryProps> = (summaryProps) => {
  return (
    <div className="right-bar">
      <Summary {...summaryProps} />
    </div>
  );
};
