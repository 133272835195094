import { Answered, Color, GuessColor } from "./types";

function fadeIn(item: HTMLElement, inps: { color: string }): Animation {
  const keyframes = [
    { fontSize: "1px", backgroundColor: "transparent" },
    { color: inps.color },
  ];
  const options = { duration: 200, easing: "ease-in" };
  return item.animate(keyframes, options);
}
function answerFadeIn(item: HTMLElement, inps: { answer: string }): Animation {
  item.textContent = `${inps.answer}`;
  const animated = fadeIn(item, { color: "#a8ad9d" });
  const cont = document.querySelector(".unanswered");
  if (cont !== null) {
    cont.className = "row-cont answered";
  }
  return animated;
}
function scoreFadeIn(item: HTMLElement, inps: { score: number }): Animation {
  item.textContent = inps.score.toString();
  const cont = document.querySelector(".colored");
  if (cont !== null) {
    cont.className = "row-cont";
  }
  const animated = fadeIn(item, { color: "#c5b453" });
  return animated;
}

interface FlipOptions {
  duration: number;
  easing: string;
}

function flipToGreen(letterHTML: HTMLElement, options: FlipOptions): Animation {
  const keyframes = [
    {
      backgroundColor: "transparent",
      borderColor: "#805e78",
      transform: "rotateX(0deg)",
    },
    {
      backgroundColor: "#50a16f",
      borderColor: "#50a16f",
      transform: "rotateX(180deg)",
    },
  ];
  letterHTML.className = "item green";
  return letterHTML.animate(keyframes, options);
}
function flipToYellow(
  letterHTML: HTMLElement,
  options: FlipOptions
): Animation {
  const keyframes = [
    {
      backgroundColor: "transparent",
      borderColor: "#805e78",
      transform: "rotateX(0deg)",
    },
    {
      backgroundColor: "#c5b453",
      borderColor: "#c5b453",
      transform: "rotateX(180deg)",
    },
  ];
  letterHTML.className = "item yellow";
  return letterHTML.animate(keyframes, options);
}
function flipToBlack(letterHTML: HTMLElement, options: FlipOptions): Animation {
  const keyframes = [
    {
      backgroundColor: "transparent",
      borderColor: "#805e78",
      transform: "rotateX(0deg)",
    },
    {
      backgroundColor: "#534c58",
      borderColor: "#534c58",
      transform: "rotateX(180deg)",
    },
  ];
  letterHTML.className = "item black";
  return letterHTML.animate(keyframes, options);
}

function flipLetter(
  letterHTML: HTMLElement,
  inps: { color: Color }
): Animation {
  const flipOptions: FlipOptions = { duration: 300, easing: "ease-in-out" };
  return inps.color === "🟩"
    ? flipToGreen(letterHTML, flipOptions)
    : inps.color === "🟨"
    ? flipToYellow(letterHTML, flipOptions)
    : flipToBlack(letterHTML, flipOptions);
}

function colorGuess(
  guessHTML: HTMLElement,
  inps: { guessColor: GuessColor }
): Animation {
  const colorInputs: { color: Color }[] = inps.guessColor.map((lc) => {
    return { color: lc.color };
  });
  const recInps: RecurseInp[] = makeRecurseInps(
    Array(5).fill(flipLetter),
    colorInputs,
    guessHTML
  );
  animateSequence(recInps, () => {});
  const cont = document.querySelector(".answered");
  if (cont !== null) {
    cont.className = "row-cont colored";
  }

  return guessHTML.animate([], { duration: 1500 });
}

type AnimationCallback = (element: HTMLElement, inps: any) => Animation;
interface RecurseInp {
  cbFunc: AnimationCallback;
  element: HTMLElement;
  input: Object;
}
type Finally = () => void;

function recurse(
  [head, ...tail]: RecurseInp[],
  prevAnim: Animation | null,
  animsFinishedHandler: Finally
) {
  const { cbFunc, element, input } = head;
  const curAnim = cbFunc(element, input);

  curAnim.onfinish = () => {
    if (tail.length === 0) {
      // console.log("Animation finished:ST");
      animsFinishedHandler();
      // console.log("Animation finished:SU");
    } else {
      recurse(tail, curAnim, animsFinishedHandler);
    }
  };
}

function animateSequence(
  recurseInps: RecurseInp[],
  animsFinishedHandler: Finally
) {
  return recurse(recurseInps, null, animsFinishedHandler);
}

function dummyFunc(el: HTMLElement, inps: {}): Animation {
  return el.animate([], { duration: 1 });
}

function makeRecurseInp(
  func: AnimationCallback,
  inp: Object,
  element: HTMLElement
): RecurseInp {
  return { cbFunc: func, input: inp, element };
}

function makeRecurseInps(
  funcs: AnimationCallback[],
  inps: Object[],
  container: HTMLElement
): RecurseInp[] {
  // console.log(Array.from(container.children));
  const elementArr = Array.from(container.children) as HTMLElement[];
  return elementArr.map((el, idx) => makeRecurseInp(funcs[idx], inps[idx], el));
}

export function animateOnAnswered(
  entry: Answered,
  container: HTMLElement,
  animsFinishedHandler: Finally
) {
  // console.log("ijoajdflajks");
  const funcs: AnimationCallback[] = [
    dummyFunc,
    answerFadeIn,
    colorGuess,
    scoreFadeIn,
  ];
  const inps = [
    {},
    { answer: entry.answer },
    { guessColor: entry.guess },
    { score: entry.score },
  ];
  return animateSequence(
    makeRecurseInps(funcs, inps, container),
    animsFinishedHandler
  );
}
