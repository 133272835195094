import React, { useState } from "react";
import { getTime, updateTimer } from "../actions/time";

export interface TimerProps {
  startTime: Date;
}

export const Timer: React.FC<TimerProps> = ({ startTime }) => {
  const [timer, setTimer] = useState(getTime(startTime));
  updateTimer(startTime, setTimer);

  return (
    <div className="next-botdle">
      Time until next botdle:
      <div className="timer">
        {`${23 - timer.hours}:${59 - timer.minutes}:${59 - timer.seconds}`}
      </div>
    </div>
  );
};
